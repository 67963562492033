(($) => {
    $(document).ready(function () {
        $(window).scroll(function () {
            //HEADER
            if($(this).scrollTop() > 0){
                $('#header').addClass('scroll');
            }else{
                $('#header').removeClass('scroll');
            }

            //BACK TO TOP
            if ($(this).scrollTop() > 400) {
                $("#backtotop")
                    .removeClass("opacity-0 invisible")
                    .addClass("opacity-100 visible");
            } else {
                $("#backtotop")
                    .removeClass("opacity-100 visible")
                    .addClass("opacity-0 invisible");
            }
        });

        $("#backtotop").click(function () {
            $("html, body").animate(
                {
                    scrollTop: "0px",
                },
                500
            );
        });

        //LIGHTGALLERY WORDPRESS
        $(".wp-block-gallery,.lightgallery")
            .lightGallery({
                download: false,
                selector: "a",
                thumbnail: false
            })
            .on("onBeforeSlide.lg", function () {
                $(".lg-backdrop").css("opacity", "0.8");
                $(".lg-sub-html").css("opacity", "0");
            });

        //HEADER
        $('#menu-burger').click(function (){
           $('#header').toggleClass('open');
        });
        //MENU SUB-MENU
        $('#menu-header .menu-item.menu-item-has-children').click(function (){
            if(window.matchMedia(`(max-width: 1280px)`).matches){
                $(this).toggleClass('open');
            }
        });

        //MODAL
        $('.modal--event').click(function (e) {
            e.preventDefault();
            var content = $(this).find('.modal--content').html();
            openModal(content);
        });
        $('.modal--bg, #modal .close').click(function () {
            closeModal();
        });
        function openModal(content) {
            $('#modal').find('.container').append(content);
            $('.modal--container').addClass('show');
            $('html, body').addClass('overflow-hidden');
        }
        function closeModal() {
            $('.modal--container').removeClass('show');
            $('html, body').removeClass('overflow-hidden');
            setTimeout(function () {
                $('#modal').find('.container').empty();
            }, 300);
        }

        //ACCORDIONS
        $('.accordion .accordion--label').click(function (){
            if($(window).width() < 768){
                const accordion = $(this).parent();
                accordion.toggleClass('active');
                accordion.find('.accordion--content').stop().slideToggle(300);
            }
        });

    });
})(jQuery);